import axios from '@/config/httpConfig'

// 保存日程预约
export function appointmentSave(data) {
  return axios.post('appointment/save', data)
}

// 日程预约 初始化
export function initAllSelectOptions(data) {
  return axios.post('/appointment/initAllSelectOptions', data)
}
