<!--  -->
<template>
  <div class="bookingdetail">
    <div class="booking" v-loading="loading">
      <div class="colltitle">
        <el-collapse v-model="collapse">
          <el-collapse-item name="1">
            <template slot="title">
              <div class="collapseHead collapse-title">
                <span>{{ $t("label.appointment.wizard.title1") }}</span>
              </div>
            </template>
            <div class="long">
              <h5>1、{{ $t("label.appointment.wizard.subtitle1") }}</h5>
              <div class="buton">
                <el-button
                  v-for="(item, index) in times"
                  :key="index"
                  :type="
                    item.value == appointment.meetinglength ? 'warning' : ''
                  "
                  :class="
                    item.value == appointment.meetinglength ? '' : 'buttonlong'
                  "
                  @click="butclick(item)"
                  >{{ item.label }}</el-button
                >
              </div>
              <h5>2、{{ $t("label.appointment.wizard.subtitle2") }}</h5>
              <div class="vaild">
                <el-date-picker
                  v-model="dateList"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  :range-separator="
                    $t('label.forecast.customforecast.submit.zhi')
                  "
                  :start-placeholder="$t('label.campagin.roi.ksrq')"
                  :end-placeholder="
                    $t('label.schedulejob.setup.schedule.enddate')
                  "
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
              <h5>3、{{ $t("label.appointment.wizard.subtitle3") }}</h5>
              <div class="three">
                <div class="dates" v-for="(we, wenum) in weeks" :key="wenum">
                  <h4>{{ we.week }}</h4>
                  <div class="appointmenttime" @click="appoint(wenum)">
                    <p v-for="(it, nu) in we.date" :key="nu">
                      {{ it.start }}-{{ it.end }}
                    </p>
                  </div>
                </div>
              </div>
              <h5>4、{{ $t("label.appointment.wizard.subtitle4") }}</h5>
              <div class="bufferor">
                <div class="webuffer">
                  {{ $t("label.appointment.wizard.subtitle4-1") }}
                  <el-select v-model="appointment.traveltime" placeholder="">
                    <el-option
                      v-for="item in travelTimeOptions"
                      :key="item.sortorder"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="webuffer">
                  {{ $t("label.appointmentdetail.wizard.title7") }}
                  <el-select v-model="appointment.bookbefore" placeholder="">
                    <el-option
                      v-for="item in bookbeforeOptions"
                      :key="item.sortorder"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="webuffer">
                  {{ $t("label.appointment.wizard.subtitle4-3") }}
                  <el-select v-model="appointment.startinevery" placeholder="">
                    <el-option
                      v-for="item in startineveryOptions"
                      :key="item.sortorder"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="webuffer">
                  {{ $t("label.appointmentdetail.wizard.title12") }}
                  <el-select
                    v-model="appointment.farthestbooktime"
                    placeholder=""
                  >
                    <el-option
                      v-for="item in farthestbooktimeOptions"
                      :key="item.sortorder"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div></div>
              <h5>5、{{ $t("label.appointment.disable.function") }}</h5>
              <div class="forbidden">
                <p>
                  <el-checkbox v-model="isrescheduled" />
                  {{ $t("label.appointment.disable.reappointment") }}
                </p>
                <p>
                  <el-checkbox v-model="iscanceled" />
                  {{ $t("label.appointment.disable.cancel") }}
                </p>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <template slot="title">
              <div class="collapseHead collapse-title">
                <span>{{ $t("label.appointment.wizard.title2") }}</span>
              </div>
            </template>
            <div class="marketdetail">
              <div class="deta" v-for="(li, num) in detalist" :key="num">
                <p>
                  <i class="errorIcon" v-show="li.key === 'name'">*</i
                  >{{ li.val }}
                </p>
                <input type="text" v-model="appointment[li.key]" />
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="footer">
        <el-button
          type="warning"
          @click="save"
          :loading="saveLoading"
          :disabled="saveLoading"
          v-preventReClick
        >
          {{ $t("component.task.button.save") }}
        </el-button>
        <el-button class="buttonlong" @click="cancel">
          {{ $t("pagecreator.button.cancel") }}
        </el-button>
      </div>
    </div>
    <!-- 选择时间 -->
    <el-dialog
      :title="$t('label.availabilityobject.wizard.title') + ':' + dislogtitlt"
      :visible.sync="dialogVisible"
      width="450px"
    >
      <div class="timepickBox">
        <p class="timepick" v-for="(pick, pickid) in pickerdate" :key="pickid">
          {{ pickid + 1 }}、
          <el-select placeholder="" v-model="pick.start">
            <el-option
              v-for="(item, optionIndex) in timeList"
              :key="item"
              :label="item"
              :value="item"
              :disabled="isDisabledStar(pick.end, optionIndex)"
            >
            </el-option>
          </el-select>
          <span>－</span>
          <el-select placeholder="" v-model="pick.end">
            <el-option
              v-for="(item, optionIndex) in timeList"
              :key="item"
              :label="item"
              :value="item"
              :disabled="isDisabledEnd(pick.start, optionIndex)"
            ></el-option>
          </el-select>
          <span @click="dele(pickid)" class="timeselect" style="color: #e35a5a"
            >－</span
          >
          <span @click="add()" class="timeselect">＋</span>
        </p>
      </div>
      <span slot="footer" class="diafooter">
        <el-button @click="sure()" type="warning">{{
          $t("component.task.button.save")
        }}</el-button>
        <el-button @click="dialogVisible = false" class="buttonlong">{{
          $t("pagecreator.button.cancel")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 日程预约
 */
import * as CommonObjApi from "./api";

export default {
  data() {
    return {
      saveLoading: false,
      requeirdName: false,
      dateList: ["", ""],
      loading: true,
      collapse: ["1", "2"],
      times: [],
      travelTimeOptions: [],
      bookbeforeOptions: [],
      farthestbooktimeOptions: [],
      startineveryOptions: [],
      timeList: [
        "00:00am",
        "00:15am",
        "00:30am",
        "00:45am",
        "01:00am",
        "01:15am",
        "01:30am",
        "01:45am",
        "02:00am",
        "02:15am",
        "02:30am",
        "02:45am",
        "03:00am",
        "03:15am",
        "03:30am",
        "03:45am",
        "04:00am",
        "04:15am",
        "04:30am",
        "04:45am",
        "05:00am",
        "05:15am",
        "05:30am",
        "05:45am",
        "06:00am",
        "06:15am",
        "06:30am",
        "06:45am",
        "07:30am",
        "07:45am",
        "08:00am",
        "08:15am",
        "08:30am",
        "08:45am",
        "09:00am",
        "09:15am",
        "09:30am",
        "09:45am",
        "10:00am",
        "10:15am",
        "10:30am",
        "10:45am",
        "11:00am",
        "11:15am",
        "11:30am",
        "11:45am",
        "12:00pm",
        "12:15pm",
        "12:30pm",
        "12:45pm",
        "01:00pm",
        "01:15pm",
        "01:30pm",
        "01:45pm",
        "02:00pm",
        "02:15pm",
        "02:30pm",
        "02:45pm",
        "03:00pm",
        "03:15pm",
        "03:30pm",
        "03:45pm",
        "04:00pm",
        "04:15pm",
        "04:30pm",
        "04:45pm",
        "05:00pm",
        "05:15pm",
        "05:30pm",
        "05:45pm",
        "06:00pm",
        "06:15pm",
        "06:30pm",
        "06:45pm",
        "07:30pm",
        "07:45pm",
        "08:00pm",
        "08:15pm",
        "08:30pm",
        "08:45pm",
        "09:00pm",
        "09:15pm",
        "09:30pm",
        "09:45pm",
        "10:00pm",
        "10:15pm",
        "10:30pm",
        "10:45pm",
        "11:00pm",
        "11:15pm",
        "11:30pm",
        "11:45pm",
      ],
      weeks: [
        {
          week: this.$i18n.t("label.appointment.wizard.subtitle3-1"),
          date: [],
        },
        {
          week: this.$i18n.t("label.appointment.wizard.subtitle3-2"),
          date: [],
        },
        {
          week: this.$i18n.t("label.appointment.wizard.subtitle3-3"),
          date: [],
        },
        {
          week: this.$i18n.t("label.appointment.wizard.subtitle3-4"),
          date: [],
        },
        {
          week: this.$i18n.t("label.appointment.wizard.subtitle3-5"),
          date: [],
        },
        {
          week: this.$i18n.t("label.appointment.wizard.subtitle3-6"),
          date: [],
        },
        {
          week: this.$i18n.t("label.appointment.wizard.subtitle3-7"),
          date: [],
        },
      ],
      detalist: [
        {
          val: this.$i18n.t("label.appointmentdetail.wizard.title1"),
          key: "name",
        },
        {
          val: this.$i18n.t("label.appointmentdetail.wizard.title13"),
          key: "description",
        },
        {
          val: this.$i18n.t("label.appointmentdetail.wizard.title6"),
          key: "location",
        },
        {
          val: this.$i18n.t("label.appointment.wizard.subtitle8"),
          key: "companyname",
        },
      ],
      dialogVisible: false,
      dislogid: null,
      dislogtitlt: "",
      pickerdate: [],
      isrescheduled: false,
      iscanceled: false,
      appointment: {
        bookbefore: "",
        meetinglength: "",
        fromdate: "",
        enddate: "",
        name: "",
        traveltime: "",
        farthestbooktime: "",
        startinevery: "",
        location: "",
        description: "",
        companyname: "",
        mondayplan: "",
        tuesdayplan: "",
        wednesdayplan: "",
        thursdayplan: "",
        fridayplan: "",
        saturdayplan: "",
        sundayplan: "",
        isrescheduled: "",
        iscanceled: "",
        id: "",
      },
       pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now()-24*60*60*1000;
        }
        }
    };
  },
  created() {
    this.init();
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    isDisabledStar(star, index) {
      if (this.timeList.indexOf(star) !== -1) {
        return index > this.timeList.indexOf(star);
      } else {
        return false;
      }
    },
    isDisabledEnd(end, index) {
      return index < this.timeList.indexOf(end);
    },
    save() {
      this.saveLoading = true;
      this.formatSave();
      if (this.$route.query.id) {
        this.appointment.id = this.$route.query.id;
      }
      this.appointment.isrescheduled = this.isrescheduled.toString();
      this.appointment.iscanceled = this.iscanceled.toString();
      if (this.dateList) {
        this.appointment.fromdate = this.dateList[0];
        this.appointment.enddate = this.dateList[1];
      } else {
        this.appointment.fromdate = "";
        this.appointment.enddate = "";
      }

      //整合保存数据
      if (this.appointment.name === "") {
        this.saveLoading = false;
        this.$message.error(this.$i18n.t("font.market.module.v1.save.name"));
        this.requeirdName = true;
        //
      } else if (
        this.appointment.mondayplan === "" &&
        this.appointment.tuesdayplan === "" &&
        this.appointment.wednesdayplan === "" &&
        this.appointment.thursdayplan === "" &&
        this.appointment.fridayplan === "" &&
        this.appointment.saturdayplan === "" &&
        this.appointment.sundayplan === "" &&
        this.appointment.mondayplan === ""
      ) {
        this.saveLoading = false;
        this.$message.error(this.$i18n.t("label.appointment.wizard.subtitle3"));
      } else if (this.appointment.meetinglength.length === 0) {
        this.saveLoading = false;
        this.$message.error(this.$i18n.t("label.appointment.wizard.subtitle1"));
      } else {
        CommonObjApi.appointmentSave(this.appointment).then((res) => {
          if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("label.webtocloudcc.submit.success"),
            });
            this.saveLoading = false;
            this.$router.go(-1);
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
            this.saveLoading = false;
          }
        });
      }
    },
    
    formatSave() {
      let indexList = [];
      this.weeks.forEach((el) => {
        let weeksStr = "";
        el.date.forEach((dateList) => {
          let str = "";
          str = dateList.start + "-" + dateList.end;
          weeksStr += str + ",";
        });
        // 去除逗号
        if (weeksStr.length > 0) {
          weeksStr = weeksStr.substr(0, weeksStr.length - 1);
        }
        indexList.push(weeksStr);
      });
      this.appointment.mondayplan = indexList[0];
      this.appointment.tuesdayplan = indexList[1];
      this.appointment.wednesdayplan = indexList[2];
      this.appointment.thursdayplan = indexList[3];
      this.appointment.fridayplan = indexList[4];
      this.appointment.saturdayplan = indexList[5];
      this.appointment.sundayplan = indexList[6];
    },
    init() {
      CommonObjApi.initAllSelectOptions({ id: this.$route.query.id }).then(
        (res) => {
          if (res.result) {
            // 如果是日程预约，不用标准弹窗。
            this.times = res.data.meetinglengthOptions;
            this.dateList = [res.data.fromdate, res.data.enddate];
            this.travelTimeOptions = res.data.travelTimeOptions;
            this.appointment.traveltime = res.data.travelTimeOptions[0].value//日程间的交通时间默认值
            this.bookbeforeOptions = res.data.bookbeforeOptions;
            this.appointment.bookbefore = res.data.bookbeforeOptions[0].value//最晚日程提醒时间默认值
            this.farthestbooktimeOptions = res.data.farthestbooktimeOptions;
            this.appointment.farthestbooktime = res.data.farthestbooktimeOptions[0].value//最长可以提前预约的时间默认值
            this.startineveryOptions = res.data.startineveryOptions;
            this.appointment.startinevery = res.data.startineveryOptions[0].value//被邀请者可以选择开始会议的间隔默认值
            if (res.data.appointment !== null) {
              this.dateList = [
                res.data.appointment.fromdate,
                res.data.appointment.enddate,
              ];
              this.appointment.id = res.data.appointment.id;
              this.appointment.meetinglength =
                res.data.appointment.meetinglength;
              this.appointment.bookbefore = res.data.appointment.bookbefore;
              this.appointment.traveltime = res.data.appointment.traveltime;
              this.appointment.farthestbooktime =
                res.data.appointment.farthestbooktime;
              this.appointment.startinevery = res.data.appointment.startinevery;
              this.appointment.name = res.data.appointment.name;
              this.appointment.location = res.data.appointment.location;
              this.appointment.description = res.data.appointment.description;
              this.appointment.companyname = res.data.appointment.companyname;
              this.isrescheduled = this.isFormat(
                res.data.appointment.isrescheduled
              );
              this.iscanceled = this.isFormat(res.data.appointment.iscanceled);
              
              this.weeks[0].date = this.formatAppointment(
                res.data.appointment.mondayplan
              );
              this.weeks[1].date = this.formatAppointment(
                res.data.appointment.tuesdayplan
              );
              this.weeks[2].date = this.formatAppointment(
                res.data.appointment.wednesdayplan
              );
              this.weeks[3].date = this.formatAppointment(
                res.data.appointment.tuesdayplan
              );
              this.weeks[4].date = this.formatAppointment(
                res.data.appointment.fridayplan
              );
              this.weeks[5].date = this.formatAppointment(
                res.data.appointment.saturdayplan
              );
              this.weeks[6].date = this.formatAppointment(
                res.data.appointment.sundayplan
              );
            }
            this.loading = false;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        }
      );
    },
    isFormat(data) {
      if (data && data === "true") {
        return true;
      } else {
        return false;
      }
    },
    formatAppointment(str) {
      let weekshow = [];
      if (str && str !== "") {
        let weekList = str.split(",");
        weekList.forEach((el) => {
          let list = el.split("-");
          let weekObj = {
            start: list[0],
            end: list[1],
          };
          weekshow.push(weekObj);
        });
      }
      return weekshow;
    },
    //选择时长
    butclick(item) {
      this.appointment.meetinglength = item.value;
    },
    //选择时间
    appoint(wenum) {
      this.dislogid = wenum;
      this.dislogtitlt = this.weeks[wenum].week;
      if (this.weeks[wenum].date.length < 1) {
        this.pickerdate = [
          {
            start: "",
            end: "",
          },
        ];
      } else {
        this.pickerdate = [...this.weeks[wenum].date];
      }
      this.dialogVisible = true;
    },
    //增加时间
    add() {
      let obj = {
        start: "",
        end: "",
      };
      this.pickerdate.push(obj);
    },
    //删除时间
    dele(num) {
      this.pickerdate.splice(num, 1);
      if (this.pickerdate.length < 1) {
        this.pickerdate = [
          {
            start: "",
            end: "",
          },
        ];
      }
    },
    sure() {
      let arr = [];
      for (var i = 0; i < this.pickerdate.length; i++) {
        if (this.pickerdate[i].start && this.pickerdate[i].end) {
          arr.push(this.pickerdate[i]);
        }
      }
      this.dialogVisible = false;
      this.weeks[this.dislogid].date = [...arr];
      this.pickerdate = [];
    },
  },
};
</script>

<style lang='scss' scoped >
.redError {
  border: 1px solid #d85e5e;
}
.errorIcon {
  margin-right: 2px;
  color: #d85e5e;
}
::v-deep .el-input__inner {
  width: 140px;
}
::v-deep :focus {
  outline: 0;
}
.collapse-title {
  flex: 1 0 90%;
  order: 1;
}
::v-deep .el-collapse-item__header {
  flex: 1 0 auto;
  order: -1;
  background: #f8f8f8;
  width: 100%;
  border-radius: 3px;
  height: 30px;
  border: none;
  margin-top: 30px;
  line-height: 30px;
  padding-left: 10px;
  font-size: 13px;
  color: #333333;
  vertical-align: middle;
}
::v-deep .el-collapse-item__wrap {
  border-bottom: none;
}
.colltitle {
  width: 98%;
  margin-left: 1%;
}
.bookingdetail {
  width: 100%;
  background: #fff;
  min-height: 100vh;
  overflow: auto;
  .booking {
    width: 98.4%;
    margin-left: 0.8%;
    font-family: "Microsoft yahei";
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 20px;
  }
}
.buttonlong:hover {
  color: #e5851a;
  border: 1px solid #e5851a;
  background: #fff;
}
.long {
  width: 96%;
  margin-left: 2%;
  margin-top: 30px;
  h5 {
    font-family: "Microsoft yahei";
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    margin-top: 10px;
  }

  .buton {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 96%;
    margin-left: 2%;
  }
  .vaild {
    width: 96%;
    margin-left: 2%;
    margin-top: 30px;
    margin-bottom: 20px;
    .el-date-editor {
      width: 400px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .bufferor {
    width: 98%;
    margin-left: 2%;
    margin-top: 15px;
    margin-bottom: 20px;
    .webuffer {
      width: 40%;
      margin-right: 10%;
      float: left;
      text-align: right;
      margin-top: 15px;
      ::v-deep .el-input--suffix {
        margin-left: 10px;
      }
    }
  }
  .forbidden {
    width: 96%;
    margin-left: 2%;
    margin-top: 20px;
    input {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
}
.footer {
  width: 100%;
  height: 70px;
  border-top: 1px solid #ddd;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.marketdetail {
  width: 96%;
  margin-left: 2%;
  margin-top: 15px;
  .deta {
    width: 40%;
    margin-left: 5%;
    margin-right: 5%;
    float: left;
    margin-top: 15px;
    p {
      width: 100%;
      line-height: 20px;
      margin-bottom: 3px;
    }
    input {
      width: 100%;
      padding-left: 8px;
      outline: none;
      border: 1px solid #ddd;
      border-radius: 3px;
      height: 38px;
    }
    input:hover {
      border: 1px solid #e5851a;
    }
  }
}
.three {
  width: 98%;
  margin-left: 2%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 15px;
  .dates {
    margin-right: 30px;
    width: 150px;
    align-items: center;
    h4 {
      text-align: center;
    }
    .appointmenttime {
      width: 100%;
      min-height: 40px;
      border: 1px solid #ddd;
      border-radius: 3px;
      p {
        width: 100%;
        text-align: center;
        line-height: 20px;
        margin: 0;
        margin-top: 7px;
        margin-bottom: 3px;
        font-size: 16px;
      }
    }
    .appointmenttime:hover {
      color: #e5851a;
      border: 1px solid #e5851a;
      background: #fff;
    }
  }
}
.timepickBox {
  margin: 30px 0px;
  .timepick {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .el-date-editor {
      width: 120px;
    }
    span {
      font-size: 20px;
      font-weight: bold;
    }
    .timeselect {
      cursor: pointer;
    }
  }
  .timepick {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .el-date-editor {
      width: 120px;
    }
    span {
      font-size: 20px;
      font-weight: bold;
    }
    .timeselect {
      cursor: pointer;
    }
  }
}

.diafooter {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-collapse {
  border-top: 0px;
  border-bottom: 1px solid #ebeef5;
}
</style>
